import React, { useState } from 'react';
import { Button, FormControl, FormLabel, Input, Sheet, Typography, Table } from '@mui/joy';
import axios from 'axios';
import domain from '../../util/domain';

const StudentDownload = () => {
    const [formData, setFormData] = useState({
        year: '',
        collegeId: ''
    });
    const [loading, setLoading] = useState(false);
    const [students, setStudents] = useState([]);
    const [error, setError] = useState(null);

    const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        if (isNaN(date.getTime())) return dateString; // Return original if invalid

        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const day = String(date.getDate()).padStart(2, '0');
        const month = months[date.getMonth()];
        const year = date.getFullYear();

        return `${day}-${month}-${year}`;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const downloadCSV = () => {
        if (!students.length) return;

        // Define headers for CSV
        const headers = ['Student ID', 'Name', 'Year of Passing', 'Join Date', 'Qualification', 'Specialization', 'College Code', 'Selected Date', 'Company Name'];

        // Convert data to CSV format
        const csvData = [
            headers.join(','), // Header row
            ...students.map(student => [
                student.studentid,
                `"${student.name}"`, // Wrap in quotes to handle commas in names
                student.yop,
                formatDate(student.joindate),
                `"${student.qualification}"`,
                `"${student.specialization}"`,
                student.ugcollegecode,
                formatDate(student.selected_date),
                student.companyid
            ].join(','))
        ].join('\n');

        // Create blob and download
        const blob = new Blob([csvData], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('hidden', '');
        a.setAttribute('href', url);
        a.setAttribute('download', `students_${formData.collegeId}_${formData.year}.csv`);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        try {
            const response = await axios.get(`${domain}/students/download`, {
                params: {
                    year: formData.year,
                    collegeId: formData.collegeId
                }
            });

            if (response.data.success) {
                setStudents(response.data.data);
            } else {
                setError('Failed to fetch student data');
            }
        } catch (error) {
            console.error('Error fetching students:', error);
            setError(error.message || 'Error fetching students');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Sheet
            sx={{
                width: '90%',
                maxWidth: 1200,
                mx: 'auto',
                my: 4,
                py: 3,
                px: 2,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                borderRadius: 'sm',
                boxShadow: 'md',
            }}
        >
            <div>
                <Typography level="h4" component="h1">
                    Download Students List
                </Typography>
                <Typography level="body-sm">
                    Enter year and college ID to download student information
                </Typography>
            </div>

            <form onSubmit={handleSubmit}>
                <FormControl sx={{ mb: 2 }}>
                    <FormLabel>Year</FormLabel>
                    <Input
                        name="year"
                        type="number"
                        placeholder="Enter year (e.g., 2022)"
                        value={formData.year}
                        onChange={handleChange}
                        required
                    />
                </FormControl>

                <FormControl sx={{ mb: 2 }}>
                    <FormLabel>College ID</FormLabel>
                    <Input
                        name="collegeId"
                        placeholder="Enter college ID (e.g., C-15788)"
                        value={formData.collegeId}
                        onChange={handleChange}
                        required
                    />
                </FormControl>

                <Button
                    type="submit"
                    loading={loading}
                    sx={{ width: '100%', mb: 2 }}
                >
                    Fetch Students List
                </Button>
            </form>

            {error && (
                <Typography color="danger" sx={{ mt: 2 }}>
                    {error}
                </Typography>
            )}

            {students.length > 0 && (
                <>
                    <Button
                        onClick={downloadCSV}
                        sx={{ alignSelf: 'flex-end', mb: 2 }}
                        color="success"
                    >
                        Download as CSV
                    </Button>

                    <Sheet sx={{ width: '100%', overflow: 'auto' }}>
                        <Table
                            stickyHeader
                            hoverRow
                            sx={{
                                '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
                                '--Table-headerUnderlineThickness': '1px',
                                '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
                                '--TableCell-paddingY': '4px',
                                '--TableCell-paddingX': '8px',
                            }}
                        >
                            <thead>
                                <tr>
                                    <th>Student ID</th>
                                    <th>Name</th>
                                    <th>Year of Passing</th>
                                    <th>Join Date</th>
                                    <th>Qualification</th>
                                    <th>Specialization</th>
                                    <th>College Code</th>
                                    <th>Selected Date</th>
                                    <th>Company Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                {students.map((student, index) => (
                                    <tr key={student.studentid || index}>
                                        <td>{student.studentid}</td>
                                        <td>{student.name}</td>
                                        <td>{student.yop}</td>
                                        <td>{formatDate(student.joindate)}</td>
                                        <td>{student.qualification}</td>
                                        <td>{student.specialization}</td>
                                        <td>{student.ugcollegecode}</td>
                                        <td>{formatDate(student.selected_date)}</td>
                                        <td>{student.companyid}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Sheet>
                </>
            )}
        </Sheet>
    );
};

export default StudentDownload; 